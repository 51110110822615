<template>
    <div class="container">
        <b-table
            sticky-header="85vh"
            striped
            hover
            :items="matches"
            :fields="fields"
            :tbody-tr-class="blowOut">
            <template #head(Winning)="data">
                <span class="text-success">{{ data.label }}</span>
            </template>
            <template #head(Result)="data">
                <span>{{ data.label }}</span>
            </template>
            <template #head(Losing)="data">
                <span class="text-secondary">{{ data.label }}</span>
            </template>

            <template #cell(Details)="data">
                <div class="detail-elements">
                    <b-button
                        class="eye"
                        @click="showMatchDetails(data.item)"
                        variant="border-transparent bg-transparent">
                        <b-icon icon="eye"></b-icon>
                    </b-button>
                </div>
                <div class="detail-elements">
                    {{ formatDate(data.item.date) }}
                </div>
            </template>

            <template #cell(Winning)="data">
                <PlayerBadge
                    variant="success"
                    :name="
                        data.item.team1.goals > data.item.team2.goals
                            ? data.item.team1.player1.name
                            : data.item.team2.player1.name
                    "
                    @show-player-modal="showPlayerModal">
                </PlayerBadge>
                <PlayerBadge
                    variant="success"
                    :name="
                        data.item.team1.goals > data.item.team2.goals
                            ? data.item.team1.player2.name
                            : data.item.team2.player2.name
                    "
                    @show-player-modal="showPlayerModal">
                </PlayerBadge>
            </template>

            <template #cell(Result)="data">
                <div class="score">
                    {{
                        data.item.team1.goals > data.item.team2.goals
                            ? data.item.team1.goals +
                              ":" +
                              data.item.team2.goals
                            : data.item.team2.goals +
                              ":" +
                              data.item.team1.goals
                    }}
                </div>
            </template>

            <template #cell(Losing)="data">
                <PlayerBadge
                    variant="secondary"
                    :name="
                        data.item.team1.goals < data.item.team2.goals
                            ? data.item.team1.player1.name
                            : data.item.team2.player1.name
                    "
                    @show-player-modal="showPlayerModal">
                </PlayerBadge>
                <PlayerBadge
                    variant="secondary"
                    :name="
                        data.item.team1.goals < data.item.team2.goals
                            ? data.item.team1.player2.name
                            : data.item.team2.player2.name
                    "
                    @show-player-modal="showPlayerModal">
                </PlayerBadge>
            </template>
        </b-table>

        <MatchDetails :match-details="matchDetails"></MatchDetails>
        <PlayerDetails :player-details="clickedPlayerDetails"></PlayerDetails>
    </div>
</template>

<script>
    import MatchDetails from "../components/MatchDetails.vue";
    import PlayerDetails from "../components/PlayerDetails.vue";
    import PlayerBadge from "../components/PlayerBadge.vue";
    import axios from "axios";

    export default {
        name: "MatchHistory",
        components: {
            MatchDetails,
            PlayerDetails,
            PlayerBadge,
        },
        data() {
            return {
                matches: [],
                fields: [
                    {
                        key: "Details",
                        tdClass: "align-middle",
                        thStyle: {
                            "font-size": "larger",
                        },
                    },
                    {
                        key: "Winning",
                        tdClass: "align-middle",
                        thVariant: "success",
                        thStyle: {
                            "font-size": "larger",
                        },
                    },
                    {
                        key: "Result",
                        tdClass: "align-middle",
                        thStyle: {
                            "font-size": "larger",
                        },
                    },
                    {
                        key: "Losing",
                        tdClass: "align-middle",
                        thStyle: {
                            "font-size": "larger",
                        },
                    },
                ],
                matchDetails: {},
                clickedPlayerDetails: {},
            };
        },

        methods: {
            formatDate(ISO_date) {
                const date = new Date(ISO_date);
                const formattedDate = new Intl.DateTimeFormat("de-DE", {
                    dateStyle: "short",
                    timeStyle: "short",
                }).format(date);
                return formattedDate;
            },
            blowOut(item) {
                if (
                    (item.team1.goals == 0 && item.team2.goals == 10) ||
                    (item.team1.goals == 10 && item.team2.goals == 0)
                )
                    return "table-primary";
            },
            showMatchDetails(item) {
                this.matchDetails = item;
                this.$root.$emit("bv::show::modal", "match-details-modal");
            },
            async showPlayerModal(playerID) {
                const res = await axios.get(
                    process.env.VUE_APP_ROOT_API + "/players/" + playerID
                );
                console.log(res.data);
                this.clickedPlayerDetails = res.data;
                this.$root.$emit("bv::show::modal", "player-details-modal");
            },
        },

        async mounted() {
            const res = await axios.get(
                process.env.VUE_APP_ROOT_API + "/matches"
            );
            this.matches = res.data;
        },
    };
</script>

<style scoped>
    .detail-elements {
        display: inline-block;
        vertical-align: middle;
    }
    .eye {
        padding-top: 4px !important;
    }
    .score {
        font-size: medium;
        font-weight: 700;
    }
</style>
