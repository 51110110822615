<template>
    <b-navbar-nav>
        <b-nav-item to="/">New Match</b-nav-item>
        <b-nav-item to="/new-player">New Player</b-nav-item>
        <b-nav-item to="/elo-ranking">Elo Ranking</b-nav-item>
        <b-nav-item-dropdown text="History" right>
            <b-dropdown-item to="/match-history">Match History</b-dropdown-item>
            <b-dropdown-item to="/tournament-history"
                >Tournament History</b-dropdown-item
            >
        </b-nav-item-dropdown>
        <b-nav-item to="/rules">Rules</b-nav-item>
        <b-nav-item-dropdown text="Statistics" right>
            <b-dropdown-item to="/coming-soon" disabled
                ><i>Coming Soon</i></b-dropdown-item
            >
        </b-nav-item-dropdown>
    </b-navbar-nav>
</template>

<script>
    export default {
        name: "MenuItems",
    };
</script>
