<template>
    <b-navbar sticky toggleable="md" class="bg-light">
        <b-navbar-brand class="px-3">
            <div class="brand">Vienna Office Kicker Elo</div>
        </b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse"></b-navbar-toggle>

        <b-sidebar title="Menu" id="navbar-toggle-collapse">
            <MenuItems></MenuItems>
        </b-sidebar>

        <b-collapse is-nav>
            <MenuItems></MenuItems>
        </b-collapse>
    </b-navbar>
</template>

<script>
    import MenuItems from "./MenuItems.vue";

    export default {
        name: "Navbar",
        components: {
            MenuItems,
        },
    };
</script>
