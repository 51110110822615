<template>
    <b-badge
        :variant="variant"
        class="m-1 player-pill"
        @click="$emit('show-player-modal', name)">
        {{ name }}
    </b-badge>
</template>

<script>
    export default {
        name: "PlayerBadge",
        props: {
            variant: String,
            name: String,
        },
        emits: ["show-player-modal"],
    };
</script>

<style scoped>
    .player-pill {
        font-size: medium;
    }
</style>
