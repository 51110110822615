var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('b-table',{attrs:{"sticky-header":"85vh","striped":"","hover":"","items":_vm.matches,"fields":_vm.fields,"tbody-tr-class":_vm.blowOut},scopedSlots:_vm._u([{key:"head(Winning)",fn:function(data){return [_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(data.label))])]}},{key:"head(Result)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.label))])]}},{key:"head(Losing)",fn:function(data){return [_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(data.label))])]}},{key:"cell(Details)",fn:function(data){return [_c('div',{staticClass:"detail-elements"},[_c('b-button',{staticClass:"eye",attrs:{"variant":"border-transparent bg-transparent"},on:{"click":function($event){return _vm.showMatchDetails(data.item)}}},[_c('b-icon',{attrs:{"icon":"eye"}})],1)],1),_c('div',{staticClass:"detail-elements"},[_vm._v(" "+_vm._s(_vm.formatDate(data.item.date))+" ")])]}},{key:"cell(Winning)",fn:function(data){return [_c('PlayerBadge',{attrs:{"variant":"success","name":data.item.team1.goals > data.item.team2.goals
                        ? data.item.team1.player1.name
                        : data.item.team2.player1.name},on:{"show-player-modal":_vm.showPlayerModal}}),_c('PlayerBadge',{attrs:{"variant":"success","name":data.item.team1.goals > data.item.team2.goals
                        ? data.item.team1.player2.name
                        : data.item.team2.player2.name},on:{"show-player-modal":_vm.showPlayerModal}})]}},{key:"cell(Result)",fn:function(data){return [_c('div',{staticClass:"score"},[_vm._v(" "+_vm._s(data.item.team1.goals > data.item.team2.goals ? data.item.team1.goals + ":" + data.item.team2.goals : data.item.team2.goals + ":" + data.item.team1.goals)+" ")])]}},{key:"cell(Losing)",fn:function(data){return [_c('PlayerBadge',{attrs:{"variant":"secondary","name":data.item.team1.goals < data.item.team2.goals
                        ? data.item.team1.player1.name
                        : data.item.team2.player1.name},on:{"show-player-modal":_vm.showPlayerModal}}),_c('PlayerBadge',{attrs:{"variant":"secondary","name":data.item.team1.goals < data.item.team2.goals
                        ? data.item.team1.player2.name
                        : data.item.team2.player2.name},on:{"show-player-modal":_vm.showPlayerModal}})]}}])}),_c('MatchDetails',{attrs:{"match-details":_vm.matchDetails}}),_c('PlayerDetails',{attrs:{"player-details":_vm.clickedPlayerDetails}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }