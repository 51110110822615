<template>
    <div class="p-4">
        <div class="m-3">
            <label for="newPlayerNameInput" class="form-label">
                Name of New Player
            </label>
            <input
                type="text"
                class="form-control"
                id="newPlayerNameInput"
                v-model="newPlayer" />
        </div>

        <div class="m-4 btn-container">
            <b-button
                class="btn-success"
                type="submit"
                @click="addPlayer(newPlayer)">
                Add Player
            </b-button>
        </div>

        <SubmitFeedback
            class="user-feedback"
            :successful="addingPlayerSuccessful"
            :failed="addingPlayerFailed"
            :userFeedback="userFeedback">
        </SubmitFeedback>
    </div>
</template>

<script>
    import axios from "axios";
    import SubmitFeedback from "../components/SubmitFeedback.vue";

    export default {
        name: "NewPlayer",
        components: {
            SubmitFeedback,
        },
        data() {
            return {
                newPlayer: "",
                addingPlayerFailed: false,
                addingPlayerSuccessful: false,
                userFeedback: "",
            };
        },

        methods: {
            async addPlayer(newPlayer) {
                // FIXME: Depending on the response from the server, the website should display different error texts to the user
                const body = { newPlayerName: newPlayer };
                try {
                    const res = await axios.post(
                        process.env.VUE_APP_ROOT_API + "/players",
                        body
                    );
                    this.addingPlayerSuccessful = true;
                    this.addingPlayerFailed = !this.addingPlayerSuccessful;
                    this.userFeedback =
                        'Player with name "' +
                        newPlayer +
                        '" added successfully.';
                } catch (error) {
                    console.log(error.message);
                    this.addingPlayerFailed = true;
                    this.addingPlayerSuccessful = !this.addingPlayerFailed;
                    this.userFeedback =
                        'Player with name "' +
                        newPlayer +
                        '" already exists. No new player was added!';
                }
            },
        },
    };
</script>
