<template>
    <div class="container">
        <b-table striped hover :items="tournamentHistory"></b-table>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "TournamentHistory",
        data() {
            return {
                tournamentHistory: [],
            };
        },
        methods: {
            async getData() {
                const res = await axios.get(
                    this.$devServer + "/tournament-history"
                );
                this.tournamentHistory = res.data.map((competition) => {
                    if (competition.type === "Tournament") {
                        return {
                            Name: competition.name,
                            Type: competition.type,
                            Winner:
                                competition.firstPlace.playerOne +
                                " & " +
                                competition.firstPlace.playerTwo +
                                (competition.firstPlace.teamName
                                    ? ' as "' +
                                      competition.firstPlace.teamName +
                                      '"'
                                    : ""),
                            Date: competition.date,
                        };
                    } else if (competition.type === "League") {
                        return {
                            Name: competition.name,
                            Type: competition.type,
                            Winner: competition.firstPlace.name,
                            Date: competition.date,
                        };
                    }
                });

                console.log(this.tournamentHistory);
            },
        },
        async created() {
            console.log("created");
            await this.getData();
        },
    };
</script>
