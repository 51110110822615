<template>
    <!-- TODO: Convert to a correct form / b-form ... especially the score -->
    <b-container>
        <div class="row">
            <div class="col-sm-6 mt-3">
                <b-card
                    border-variant="primary"
                    header-bg-variant="primary"
                    header-text-variant="white"
                    header="Team 1">
                    <PlayerSelector
                        :selectable-players="selectablePlayers"
                        label="Player One"
                        :activePlayerId="1"
                        @update-selected-players="updateTeamComposition">
                    </PlayerSelector>

                    <PlayerSelector
                        :selectable-players="selectablePlayers"
                        label="Player Two"
                        :activePlayerId="2"
                        @update-selected-players="updateTeamComposition">
                    </PlayerSelector>

                    <Score
                        @change-score="updateScoreTeamOne"
                        label="Goals"></Score>
                </b-card>
            </div>
            <div class="col-sm-6 mt-3">
                <b-card
                    border-variant="secondary"
                    header-bg-variant="secondary"
                    header-text-variant="white"
                    header="Team 2">
                    <PlayerSelector
                        :selectable-players="selectablePlayers"
                        label="Player One"
                        :activePlayerId="3"
                        @update-selected-players="updateTeamComposition">
                    </PlayerSelector>

                    <PlayerSelector
                        :selectable-players="selectablePlayers"
                        label="Player Two"
                        :activePlayerId="4"
                        @update-selected-players="updateTeamComposition">
                    </PlayerSelector>

                    <Score
                        @change-score="updateScoreTeamTwo"
                        label="Goals"></Score>
                </b-card>
            </div>
        </div>

        <div class="m-4 btn-container">
            <b-button
                class="btn-success"
                type="submit"
                @click="validateEnteredData">
                Submit result
            </b-button>

            <b-modal
                centered
                id="modal-result-confirmation"
                @ok="submitResult"
                title="Check entered data. Everything correct?">
                <div id="modal-content">
                    <div class="players">
                        <div><b>Team1</b></div>
                        <div>
                            {{ this.matchData.team1.player1 }}
                        </div>
                        <div>
                            {{ this.matchData.team1.player2 }}
                        </div>
                    </div>
                    <div class="score">
                        {{ this.matchData.team1.goals }} :
                        {{ this.matchData.team2.goals }}
                    </div>
                    <div class="players">
                        <div><b>Team2</b></div>
                        <div>
                            {{ this.matchData.team2.player1 }}
                        </div>
                        <div>
                            {{ this.matchData.team2.player2 }}
                        </div>
                    </div>
                </div>
                <template #modal-footer="{ ok, cancel }">
                    <b-button variant="success" @click="ok()">
                        Confirm
                    </b-button>
                    <b-button variant="danger" @click="cancel()">
                        Cancel
                    </b-button>
                </template>
            </b-modal>
        </div>

        <div class="cake-gif" v-if="cakeGifVisible">
            <b-embed type="iframe" :src="cakeGifURL"> </b-embed>
        </div>

        <SubmitFeedback
            :successful="submittingMatchSuccessful"
            :failed="submittingMatchFailed"
            :user-feedback="userFeedback">
        </SubmitFeedback>

        <!-- TODO: make an overlay that shows, after successfully submitting a game the new stats and stat changes of the players -->
    </b-container>
</template>

<script>
    import axios from "axios";
    import PlayerSelector from "../components/PlayerSelector.vue";
    import Score from "../components/Score.vue";
    import SubmitFeedback from "../components/SubmitFeedback.vue";

    export default {
        name: "NewMatch",

        components: {
            PlayerSelector,
            Score,
            SubmitFeedback,
        },

        data() {
            return {
                selectablePlayers: [],
                submittingMatchSuccessful: false,
                submittingMatchFailed: false,
                userFeedback: "",
                cakeGifURL: "",
                cakeGifVisible: false,

                matchData: {
                    date: Date.now(),
                    team1: {
                        player1: "",
                        player2: "",
                        goals: 0,
                    },
                    team2: {
                        player1: "",
                        player2: "",
                        goals: 0,
                    },
                },
            };
        },

        methods: {
            validateEnteredData() {
                const players = [
                    this.matchData.team1.player1,
                    this.matchData.team1.player2,
                    this.matchData.team2.player1,
                    this.matchData.team2.player2,
                ];

                // Different Input Validation on the Client side
                // ----
                // 1) check for duplicates in the player data on client site
                // this is actually redundant as we already disable already selected options in the dropdown
                if (new Set(players).size !== players.length) {
                    this.setSubmittingStatusFailed(true);
                    this.userFeedback =
                        "Adding match not possible. Please enter four different players";
                    return;
                }

                // 1.5) TODO: Check that only numbers have been entered
                // 2) check for an actual win (one team has 10 goals)
                if (
                    !(
                        this.matchData.team1.goals == 10 ||
                        this.matchData.team2.goals == 10
                    )
                ) {
                    this.setSubmittingStatusFailed(true);
                    this.userFeedback =
                        "Adding match not possible. At least one team has to have scored 10 goals.";
                    return;
                }

                // only if we reach this point, the validation was successful
                // now we can show the modal
                this.$root.$emit(
                    "bv::show::modal",
                    "modal-result-confirmation"
                );
            },
            async submitResult() {
                const matchData = this.matchData;

                // This is called when confirming the result in the modal
                // The modal should however only open, if the validation was correct

                // TODO: Differentiate different server side validation errors in user feedback
                const body = { newMatch: matchData };
                try {
                    await axios.post(
                        process.env.VUE_APP_ROOT_API + "/matches",
                        body
                    );
                    this.setSubmittingStatusSuccessful(true);
                    this.userFeedback =
                        "Match added successfully: " +
                        matchData.team1.player1 +
                        " & " +
                        matchData.team1.player2 +
                        " " +
                        matchData.team1.goals +
                        " vs. " +
                        +matchData.team2.goals +
                        " " +
                        matchData.team2.player1 +
                        " & " +
                        matchData.team2.player2;
                    if (
                        (matchData.team1.goals == 10 &&
                            matchData.team2.goals == 0) ||
                        (matchData.team1.goals == 0 &&
                            matchData.team2.goals == 10)
                    ) {
                        this.showCakeGif();
                    }
                } catch (error) {
                    this.setSubmittingStatusFailed(true);
                    this.userFeedback =
                        "Server side validation failed: " + error.toString();
                }

                // TODO: check if this was a 10:0, if so there should be an alarm an a fancy animation (and an email to all members)
            },

            setSubmittingStatusFailed(submittingMatchFailed) {
                this.submittingMatchFailed = submittingMatchFailed;
                this.submittingMatchSuccessful = !submittingMatchFailed;
            },

            setSubmittingStatusSuccessful(submittingMatchSuccessful) {
                this.submittingMatchSuccessful = submittingMatchSuccessful;
                this.submittingMatchFailed = !submittingMatchSuccessful;
            },

            updateTeamComposition(selectedPlayer) {
                switch (selectedPlayer.activePlayerId) {
                    case 1:
                        this.matchData.team1.player1 = selectedPlayer.name;
                        break;
                    case 2:
                        this.matchData.team1.player2 = selectedPlayer.name;
                        break;
                    case 3:
                        this.matchData.team2.player1 = selectedPlayer.name;
                        break;
                    case 4:
                        this.matchData.team2.player2 = selectedPlayer.name;
                        break;
                    default:
                        break;
                }

                // update selectable players
                const selectedPlayers = [
                    this.matchData.team1.player1,
                    this.matchData.team1.player2,
                    this.matchData.team2.player1,
                    this.matchData.team2.player2,
                ];

                this.selectablePlayers = this.selectablePlayers.map(
                    (player) => {
                        if (selectedPlayers.includes(player.text)) {
                            return { ...player, disabled: true };
                        } else {
                            return { ...player, disabled: false };
                        }
                    }
                );
            },

            updateScoreTeamOne(goals) {
                this.matchData.team1.goals = goals;
            },

            updateScoreTeamTwo(goals) {
                this.matchData.team2.goals = goals;
            },

            async getPlayers() {
                const res = await axios.get(
                    process.env.VUE_APP_ROOT_API + "/players"
                );
                this.selectablePlayers = res.data.map((player) => {
                    return {
                        value: player.name,
                        text: player.name,
                        hidden: false,
                        disabled: false,
                    };
                });
            },

            async showCakeGif() {
                const res = await axios.get(
                    "https://api.giphy.com/v1/gifs/random?api_key=bjTlQqLbNjJIL8Pilk62h8xTJIZAxePa&tag=cake&rating=g"
                );
                this.cakeGifURL = res.data.data.embed_url;
                this.cakeGifVisible = true;
            },
        },

        async created() {
            await this.getPlayers();

            // initialize selectable players
            const defaultSelection = {
                value: "",
                text: "Select player",
                hidden: true,
                disabled: false,
            };

            if (
                !this.selectablePlayers.some(
                    (player) => player.text === defaultSelection.text
                )
            ) {
                this.selectablePlayers.push(defaultSelection);
            }
        },
    };
</script>

<style scoped>
    .players {
        display: inline-block;
        vertical-align: middle;
        margin: 1rem;
    }

    .score {
        display: inline-block;
        font-weight: 700;
        padding: 1rem 1rem;
        vertical-align: middle;
    }

    .cake-gif {
        margin: 1rem;
    }

    #modal-content {
        text-align: center;
    }
</style>
