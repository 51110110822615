<template>
    <div class="container">
        <!-- FIXME: Ranking needs to be equal if two players have the same elo -->
        <!-- TODO: Add pagination -->
        <b-table striped hover :items="players" :fields="fields">
            <template #cell(rank)="data">
                {{ data.index + 1 }}
            </template>
        </b-table>
    </div>
</template>

<script>
    export default {
        name: "EloRanking",
        data() {
            return {
                players: [],
                fields: ["rank", "name", "elo"],
            };
        },

        async mounted() {
            const res = await fetch(
                process.env.VUE_APP_ROOT_API + "/players/sorted"
            );
            const data = await res.json();
            this.players = data;
        },
    };
</script>
