<template>
    <b-form-group :label="label" label-for="goals">
        <b-form-input
            @change="$emit('change-score', goals)"
            type="number"
            id="goals"
            min="0"
            max="10"
            v-model="goals">
        </b-form-input>
    </b-form-group>
</template>

<script>
    export default {
        name: "Score",
        props: {
            label: String,
        },
        data() {
            return {
                goals: 0,
            };
        },
        emits: ["change-score"],
    };
</script>
