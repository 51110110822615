<template>
    <b-modal ok-only id="match-details-modal" title="Match Details">
        <div>{{ matchDetails }}</div>
    </b-modal>
</template>

<script>
    export default {
        name: "MatchDetails",
        props: {
            matchDetails: {},
        },
    };
</script>
