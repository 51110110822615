<template>
    <b-modal ok-only id="player-details-modal" title="Player Stats">
        <b-container id="playerCardHeader">
            <div id="avatar">
                <b-avatar
                    :variant="getRandomVariant()"
                    :text="getInitials()"
                    size="6em">
                </b-avatar>
            </div>
            <div id="name" class="m-4">
                {{ this.playerDetails.name }}
            </div>
        </b-container>
        <b-container class="mt-4" id="stats">
            <b-row>
                <b-col>Elo: {{ this.playerDetails.elo }}</b-col>
                <b-col>Rank: <i>coming soon</i></b-col>
                <div class="w-100"></div>
                <b-col>Season Elo: <i>coming soon</i></b-col>
                <b-col>Season Rank: <i>coming soon</i></b-col>
                <div class="w-100"></div>
                <b-col>Win-Ratio: {{ winRatio }}</b-col>
                <b-col>Loss-Ratio {{ lossRatio }}</b-col>
                <b-col>Win-Loss-Ration {{ winLossRatio }}</b-col>
                <div class="w-100"></div>
                <b-col>Win-Ratio Season: <i>coming soon</i></b-col>
                <b-col>Loss-Ratio Season: <i>coming soon</i></b-col>
                <b-col>Win-Loss-Ration Season: <i>coming soon</i></b-col>
                <div class="w-100"></div>
                <b-col
                    >Goals scored total:
                    {{ this.playerDetails.goalsScored }}</b-col
                >
                <b-col
                    >Goals against total:
                    {{ this.playerDetails.goalsAgainst }}</b-col
                >
                <div class="w-100"></div>
                <b-col>Goals scored Season: <i>coming soon</i></b-col>
                <b-col>Goals against Season: <i>coming soon</i></b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
    export default {
        name: "PlayerDetails",
        props: {
            playerDetails: Object,
        },
        data() {
            return {
                firstName: "",
                lastName: "",
            };
        },
        computed: {
            winRatio: function () {
                return (
                    parseFloat(
                        this.playerDetails.wins / this.playerDetails.games
                    ).toFixed(2) + "%"
                );
            },

            lossRatio: function () {
                return (
                    parseFloat(
                        this.playerDetails.losses / this.playerDetails.games
                    ).toFixed(2) + "%"
                );
            },

            winLossRatio: function () {
                if (this.playerDetails.losses == 0) {
                    return "100%";
                }
                return (
                    parseFloat(
                        this.playerDetails.wins / this.playerDetails.losses
                    ).toFixed(2) + "%"
                );
            },
        },
        methods: {
            getRandomVariant() {
                const variants = [
                    "primary",
                    "secondary",
                    "success",
                    "warning",
                    "danger",
                    "info",
                    "dark",
                ];
                const r = Math.floor(Math.random() * variants.length);
                return variants[r];
            },
            getInitials() {
                try {
                    const names = this.playerDetails.name.split(" ");
                    this.firstName = names[0];
                    this.lastName = names[1];
                    const initials = names[0][0] + names[1][0];
                    return initials;
                } catch (err) {
                    return "";
                }
            },
        },
    };
</script>

<style scoped>
    #name {
        display: inline-block;
        font-size: x-large;
        font-weight: bolder;
    }

    #avatar {
        display: inline-block;
    }

    #stats {
        border: 0.1em solid black;
        border-radius: 10px;
    }
</style>
