import Vue from "vue";
import VueRouter from "vue-router";
import NewMatch from "../views/NewMatch.vue";
import NewPlayer from "../views/NewPlayer.vue";
import EloRanking from "../views/EloRanking.vue";
import MatchHistory from "../views/MatchHistory.vue";
import TournamentHistory from "../views/TournamentHistory.vue";
import Rules from "../views/Rules.vue";
import About from "../views/AboutView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "newMatch",
        component: NewMatch,
    },
    {
        path: "/new-player",
        name: "newPlayer",
        component: NewPlayer,
    },
    {
        path: "/elo-ranking",
        name: "eloRanking",
        component: EloRanking,
    },
    {
        path: "/match-history",
        name: "matchHistory",
        component: MatchHistory,
    },
    {
        path: "/tournament-history",
        name: "tournamentHistory",
        component: TournamentHistory,
    },
    {
        path: "/rules",
        name: "rules",
        component: Rules,
    },
    {
        path: "/about",
        name: "about",
        component: About,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
