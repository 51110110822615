<template>
    <div id="app">
        <Navbar></Navbar>
        <div sticky id="demo-warning">
            Attention! This is a development / demo version of the kicker-elo
            website. Entered date may be deleted or get lost at any time
        </div>
        <router-view></router-view>
        <Footer></Footer>
    </div>
</template>
<!-- TODO: Login / User Authentication -->
<script>
    import Navbar from "./components/Navbar.vue";
    import Footer from "./components/Footer.vue";

    export default {
        name: "App",
        components: {
            Navbar,
            Footer,
        },
    };
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #002b47;
        text-align: center;
    }

    #demo-warning {
        background-color: rgb(210, 0, 0);
        border: 0.2em solid white;
        color: whitesmoke;
        font-weight: bold;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 3.5em;
    }

    nav {
        padding: 30px;
    }

    nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    nav a.router-link-exact-active {
        color: var(--my-blue-color);
    }

    .brand {
        color: var(--my-blue-color);
    }

    :root {
        --my-blue-color: #009fe0;
    }
</style>
