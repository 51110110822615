<template>
    <div>
        <div class="alert alert-success"
            v-if="successful"
            role="alert">{{ userFeedback }}</div>
        <div class="alert alert-danger"
            v-if="failed"
            role="alert">{{ userFeedback }}</div>
    </div>
</template>

<script>
export default {
    name: "SubmitFeedback",
    props: {
        successful: Boolean,
        failed: Boolean,
        userFeedback: String,
    }
}
</script>