<template>
    <b-form-group :label="label">
        <b-form-select
            class="form-select"
            id="player-select"
            v-model="selectedPlayer"
            @change="
                $emit('update-selected-players', {
                    name: selectedPlayer,
                    activePlayerId: activePlayerId,
                })
            "
            required>
            <option
                v-for="(player, i) in selectablePlayers"
                :value="player.value"
                :disabled="player.disabled"
                :hidden="player.hidden">
                {{ player.text }}
            </option>
        </b-form-select>
    </b-form-group>
</template>

<script>
    export default {
        name: "PlayerSelector",
        props: {
            selectablePlayers: Array,
            label: String,
            activePlayerId: Number,
        },
        data() {
            return {
                selectedPlayer: "",
            };
        },
        emits: ["update-selected-players"],
    };
</script>
